/* ==========================================================================
  main.js
========================================================================== */

// https://somewebmedia.github.io/hc-offcanvas-nav/#magazines
// https://github.com/somewebmedia/hc-offcanvas-nav/blob/ff07651d4e2eab790f5023ff8bd5f9773912f99f/src/js/hc-offcanvas-nav.js
document.addEventListener('DOMContentLoaded', function() {

  var nav = new hcOffcanvasNav('.primary-navigation', {
    customToggle: '.menu-toggle',
    navTitle: 'Tous les produits',
    position: 'top',
    levelTitles: true,
    levelTitleAsBack: true,
    removeOriginalNav: false,
  });

});

/**
 * Init mobile menu
 */
component_mobile_menu({
  menu:     document.querySelector('.js-mobile-menu'),
  site:     document.querySelector('.js-page'),
  toggles:  document.querySelectorAll('.js-menu-toggle')
});

/**
 * Init Responsive videos
 */
fitvids();

/**
 * Init polyfill object-fit for lazyloaded
 */
if (typeof objectFitPolyfill === "function") {
  document.addEventListener('lazybeforeunveil', function(e){
    objectFitPolyfill();
    e.target.addEventListener('load', function() {
      objectFitPolyfill();
    });
  });
}

/**
 * Init lightbox
 */
if (document.querySelectorAll('.lightbox').length) {
  try {
    new Tobi({
      // variables from script localization in register-assets.php
      navLabel: [theme_strings.prev, theme_strings.next],
      closeLabel: theme_strings.close,
      loadingIndicatorLabel: theme_strings.loading,
      captionsSelector: 'self',
      captionAttribute: 'data-caption',
      zoom: false,
    });
  } catch (e) {
    console.log(e);
  }
}

/*
* Product slider in single product page with Splide JS
* https://splidejs.com/
*/
if (document.querySelectorAll('#slider-pics').length) {

  document.addEventListener( 'DOMContentLoaded', function () {

    var secondarySlider = new Splide( '#slider-thumbs', {
      direction   : 'ttb',
      height      : '43rem',
      perPage     : 4,
      arrows      : false,
      pagination  : false,
      gap         : 15,
      cover      : true,
      isNavigation: true,
      breakpoints : {
        '768': {
          destroy: true,
        }
      },
    } ).mount();

    var primarySlider = new Splide( '#slider-pics', {
      type          : 'fade',
      height        : '50rem',
      perPage       : 1,
      arrows        : false,
      pagination    : false,
      breakpoints : {
        '768': {
          height        : 0,
          pagination    : true,
        }
      },
    } ); // do not call mount() here.

    primarySlider.sync( secondarySlider ).mount();
  } );

}
